import { Icon } from '/features/buildingBlocks/Icon'
import { SplitFlap } from '/features/pageOnly/SplitFlap'

import klmLogo from '/images/logos/klm-logo.raw.svg'
import transaviaLogo from '/images/logos/transavia-logo.raw.svg'
import martinAirLogo from '/images/logos/martinair-logo.raw.svg'

import styles from './JobBoard.css'

export function JobBoard({ layoutClassName = undefined }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div className={styles.splitFlapBoard}>
        <FlipBoard icon={martinAirLogo} domain='MP' string='Pilot' />
        <FlipBoard icon={transaviaLogo} domain='HV' string='Mechanic' />
        <FlipBoard icon={klmLogo} domain='KL' string='Driver' />
        <FlipBoard icon={klmLogo} domain='KL' string='Trainer' />
        <FlipBoard icon={klmLogo} domain='KL' string='Director' />
      </div>
    </div>
  )
}

function FlipBoard({ domain, string, icon }) {
  return (
    <div className={styles.componentFlipBoard}>
      <Logo {...{ icon }} />
      <SplitFlap string={domain} layoutClassName={styles.domainLayout} />
      <SplitFlap layoutClassName={styles.splitFlapLayout} {...{ string }} />
    </div>
  )
}

function Logo({ icon }) {
  return (
    <div className={styles.componentLogo}>
      <Icon layoutClassName={styles.iconLayout} {...{ icon }} />
    </div>
  )
}
