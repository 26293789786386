import styles from './SplitFlap.css'

export function SplitFlap({ string, layoutClassName = undefined }) {
  const splitString = string.split('')

  return (
    <div className={cx(styles.component, layoutClassName)}>
      {splitString.map((letter, i) => <Letter key={i} layoutClassName={styles.letterLayout} {...{ letter }} />)}
    </div>
  )
}

function Letter({ letter, layoutClassName }) {
  const [showLetters, setShowLetters] = React.useState(true)
  const { letters, amountOfLetters } = createAlphabetArray({ letter: letter.toLowerCase() })
  const delay = amountOfLetters * 150

  React.useEffect(
    () => {
      setTimeout(() => setShowLetters(false), delay)
    }, [delay])

  return (
    <div className={cx(styles.componentLetter, layoutClassName)}>
      <div className={cx(styles.letter, styles.showLetter)} style={{ '--total-delay': amountOfLetters + 's', zIndex: -letters.length - 1 }}>
        {letter}
      </div>
      {showLetters && letters.map((letter, i) =>
        <React.Fragment key={i}>
          <div key={i} className={cx(styles.letter, styles.letterAnimation, styles.letterNextAnimation)} style={{ '--delay': i / 8 + 's', '--z-index': -i }}>
            {letter}
          </div>
          {letters[i + 1] && (
            <span className={cx(styles.letter, styles.nextLetter)}  style={{ '--delay': i / 8 + 's' }}>{letters[i + 1]}</span>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

function createAlphabetArray({ letter }) {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('')
  const index = alphabet.indexOf(letter)
  const aToIndex = [...alphabet.slice(0, index + 1)]
  const letters = [...aToIndex, letter]
  const amountOfLetters = letters.length

  return {
    letters,
    amountOfLetters
  }
}
